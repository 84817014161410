<template>
    <main
        id="js-page-content"
        class="page-content justify-content-center"
        role="main"
    >
        <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="bg-white o-hidden border-0 shadow-lg my-5">
                <div class="p-0">
                    <div class="row">
                        <div
                            class="col-lg-6 d-none d-lg-block bg-login-image"
                        ></div>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div
                                    class="flex text-center justify-content-center items-center"
                                >
                                    <img
                                        class="img-fluid m-1"
                                        src="@/assets/logo.jpg"
                                    />
                                </div>
                                <h1 class="h3 px-8 mt-5 text-blue-500">
                                    {{ $t('auth.welcome') }}
                                </h1>
                                <form
                                    class="bg-white rounded px-8 pt-6 pb-8 mb-4"
                                    method="POST"
                                >
                                    <div class="mb-4">
                                        <label
                                            class="block text-gray-700 text-sm font-bold mb-2"
                                            for="email"
                                            >{{ $t('auth.email') }}</label
                                        >
                                        <input
                                            id="email"
                                            v-model="email"
                                            autocomplete="email"
                                            autofocus
                                            name="email"
                                            :placeholder="$t('auth.email')"
                                            required
                                            type="email"
                                            class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                        <p
                                            v-if="
                                                (isForgotPasswordSubmit &&
                                                    mailMessage) ||
                                                (isSubmit && mailMessage)
                                            "
                                            class="text-red-500 text-sm font-bold text-center"
                                        >
                                            {{ mailMessage }}
                                        </p>
                                    </div>
                                    <div class="mb-6" v-if="!isForgotPassword">
                                        <label
                                            class="block text-gray-700 text-sm font-bold mb-2"
                                            for="password"
                                        >
                                            {{
                                                $t(
                                                    'auth.reset_password.password'
                                                )
                                            }}
                                        </label>
                                        <input
                                            id="password"
                                            class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            v-model="password"
                                            autocomplete="current-password"
                                            name="password"
                                            :placeholder="
                                                $t(
                                                    'auth.reset_password.password'
                                                )
                                            "
                                            required
                                            type="password"
                                        />
                                        <p
                                            v-if="
                                                (isForgotPasswordSubmit &&
                                                    passwordMessage) ||
                                                (isSubmit && passwordMessage)
                                            "
                                            class="text-red-500 text-sm font-bold text-center"
                                        >
                                            {{ passwordMessage }}
                                        </p>
                                    </div>
                                    <div
                                        class="flex justify-content-between items-center"
                                    >
                                        <div
                                            v-show="isForgotPassword"
                                            class="text-center"
                                        >
                                            <div
                                                class="hover:underline cursor-pointer text-blue-500"
                                                @click.prevent="clean()"
                                            >
                                                {{ $t('auth.login') }}
                                            </div>
                                        </div>
                                        <div
                                            v-show="!isForgotPassword"
                                            class="text-center"
                                        >
                                            <div
                                                class="hover:underline cursor-pointer text-blue-500"
                                                @click.prevent="clean()"
                                            >
                                                {{
                                                    $t(
                                                        'auth.forgot_password.submit'
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <p
                                            v-if="
                                                isForgotPasswordSubmit &&
                                                passwordResetMessage
                                            "
                                            class="text-red-500 text-sm font-bold text-center"
                                        >
                                            {{ passwordResetMessage }}
                                        </p>
                                        <button
                                            id="loginBtn"
                                            v-show="
                                                !loading && !isForgotPassword
                                            "
                                            class="m-4 inline-flex items-end px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                            @click.prevent="connection"
                                        >
                                            {{ $t('auth.login') }}
                                        </button>

                                        <button
                                            v-show="
                                                loading && !isForgotPassword
                                            "
                                            class="m-4 inline-flex items-end px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                            disabled="disabled"
                                            type="button"
                                        >
                                            <span
                                                aria-hidden="true"
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                            ></span>
                                            {{ $t('auth.login') }}...
                                        </button>
                                        <button
                                            v-show="
                                                !loading && isForgotPassword
                                            "
                                            class="m-4 inline-flex items-end px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                            @click.prevent="
                                                forgotPasswordHandler
                                            "
                                        >
                                            {{
                                                $t(
                                                    'auth.forgot_password.send_request'
                                                )
                                            }}
                                        </button>

                                        <button
                                            v-show="loading && isForgotPassword"
                                            class="m-4 inline-flex items-end px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                            disabled="disabled"
                                            type="button"
                                        >
                                            <span
                                                aria-hidden="true"
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                            ></span>
                                            {{
                                                $t(
                                                    'auth.forgot_password.send_request'
                                                )
                                            }}...
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Toaster ref="toaster" />
    </main>
</template>

<script>
import { mapActions } from 'vuex'
import Toaster from '@/components/UI/Helper/Toaster'

export default {
    name: 'LoginSanctum',
    components: {
        Toaster
    },
    data: function () {
        return {
            email: '',
            password: '',
            isSubmit: false,
            isloadingForgotPwd: false,
            loading: false,
            backendUrl: process.env.VUE_APP_BACKEND_URL,
            mailMessage: '',
            passwordMessage: '',
            passwordResetMessage: '',
            isForgotPasswordSubmit: false,
            isForgotPassword: false,
            mailregExp:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    computed: {
        errorWrongPasswordOrEmail() {
            return this.$t('error.validation.wrong_password_or_email')
        },
        errorContactManager() {
            return this.$t('error.contact_manager')
        },
        errorTitle() {
            return this.$t('error.title_error')
        }
    },
    methods: {
        ...mapActions('auth', ['login', 'forgotPassword']),
        connection: function () {
            this.loading = true
            this.isSubmit = true
            this.confirmMail(this.email)
            this.confirmPassword(this.password)
            !this.mailMessage && !this.passwordMessage
                ? this.login({ email: this.email, password: this.password })
                      .then(() => {
                          this.loading = false
                          this.$router.push('/')
                      })
                      .catch((error) => {
                          error['message'] =
                              error.response.data.error ===
                              'Wrong username or password'
                                  ? this.errorWrongPasswordOrEmail
                                  : this.errorContactManager
                          // Pour ne pas casser le store au niveau du toast , je force l'erreur a worng password / username
                          error.response.data.error =
                              'Wrong username or password'

                          error['title'] = this.errorTitle

                          this.$store
                              .dispatch('dispatchError', error)
                              .then(() => {
                                  this.loading = false
                              })
                      })
                : (this.loading = false)
        },
        forgotPasswordHandler() {
            this.loading = true
            this.isForgotPasswordSubmit = true
            this.confirmMail(this.email)
            !this.mailMessage
                ? this.forgotPassword({ email: this.email })
                      .then(() => {
                          this.isloadingForgotPwd = false
                          this.passwordResetMessage = this.$t(
                              'auth.forgot_password.success_message'
                          )
                          this.loading = false
                      })
                      .catch((error) => {
                          this.isloadingForgotPwd = false
                          error['title'] = this.errorTitle
                          this.$store
                              .dispatch('dispatchError', error)
                              .then(() => {
                                  this.loading = false
                              })
                      })
                : (this.isloadingForgotPwd = false)
        },
        // vérifie la saisie du champ email
        confirmMail(email) {
            if (!email) {
                this.mailMessage = this.$t('error.validation.required')
            } else if (!this.mailregExp.test(email)) {
                this.mailMessage = this.$t('error.validation.mail_valid')
            } else {
                this.mailMessage = ''
            }
        },
        confirmPassword(password) {
            if (!password) {
                this.passwordMessage = this.$t('error.validation.required')
            } else {
                this.passwordMessage = ''
            }
        },
        clean() {
            this.isForgotPassword = !this.isForgotPassword
            this.passwordResetMessage = ''
            this.passwordMessage = ''
            this.mailMessage = ''
            this.password = ''
        }
    },
    mounted() {}
}
</script>
